import React from "react";

/** This is an implementation of the idea described in
 * https://react.dev/learn/synchronizing-with-effects#fetching-data */
export const useMountEffect: <T>(
  f: () => Promise<T>,
  k: (t: T) => void,
) => void = (f, k) =>
  React.useEffect(() => {
    let ignore = false;
    const run = async () => {
      const res = await f();
      if (!ignore) k(res);
      return res;
    };
    run();
    return () => {
      ignore = true;
    };
  }, []);
