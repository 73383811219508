import React from "react";
import "./App.css";
import "cookie-store";
import * as Util from "./util";

export declare const cookieStore: typeof CookieStore;

type LimeSurveyContext = {
  token: string;
  firstname: string;
  lastname: string;
};

const App = () => {
  const [context, setContext] = React.useState<LimeSurveyContext | null>(null);
  const setup = () => cookieStore.get("lime-survey-context");
  const k = (cookie: Cookie | null): void => {
    if (cookie === null) {
      console.warn("Could not load lime-survey-context");
      return;
    }
    const context: unknown = JSON.parse(cookie.value);
    setContext(context as LimeSurveyContext);
  };

  Util.useMountEffect(setup, k);
  if (context === null) return <>Context is null</>;
  return (
    <table>
      <thead>
        <th>Token</th>
        <th>First Name</th>
        <th>Last Name</th>
      </thead>
      <tbody>
        <td>{context.token}</td>
        <td>{context.firstname}</td>
        <td>{context.lastname}</td>
      </tbody>
    </table>
  );
};

export default App;
